/*******************************************/
/**************** VENDORS ******************/
/*******************************************/
//
// Import Bootstrap 4 JS
//
// ! All js files are invluded
// ! You can comment what you dont want from vendor/bootstrap/_bootstrap.js file
import './vendors/_vendor.js';
import './components/_components.js'
import "flickity";
// import '../gulp/node_modules/flickity/dist/flickity.pkgd.min.js';



/*******************************************/
/**************** Components ***************/
/*******************************************/
//
// Import Components
//
// If using jquery 1.9.1 or below import the components inside the ( function($) )
(function ($) {


	$(document).ready(function () {

		if ($(".page-template-produkter").length) {
			var target = window.location.hash;
			if (target != "") {
				if ($(target).length) {
					$(document).find(".filter_buttons button" + target + "").trigger("click");
				} else {
					$(".filter_buttons button:first-of-type").trigger("click");
				}
			} else {
				$(".filter_buttons button:first-of-type").trigger("click");
			}
		}

		if ($(".page-template-kontakt").length) {
			var target = window.location.hash;
			if (target != "") {
				if ($(target).length) {
					$(document).find(".filter_buttons button" + target + "").trigger("click");
				} else {
					$(".filter_buttons button:first-of-type").trigger("click");
				}
			} else {
				$(".filter_buttons button:first-of-type").trigger("click");
			}
		}

		$(document).on("click", "#blog-search-form input", function () {
			$(this).addClass("opened");
			$(".search_button").addClass("opened");
		});

		$(document).on("click", ".search_button", function () {
			$('#blog-search-form input').toggleClass("opened");
			$(this).toggleClass("opened");
			$(".search-content").toggleClass("opened");
			setTimeout(function () {
				if ($(".search-content input").hasClass("opened")) {
					$('.search-content input').focus();
				}
			}, 400);

		});

		$(document).on("click", ".languages", function () {
			$('.language_switcher').toggleClass("opened");
			$(this).toggleClass("opened");
		});

		$(document).on("click", ".header_toggle", function () {
			$('.switchers_holder').toggleClass('hide');
			$(this).toggleClass("active");
			$('.produkt_cats').toggleClass("active");
		});

		$(document).on("click", ".carousel_arrow", function () {
			$('.slick-next.slick-arrow').trigger("click");
		});

		$(document).on("click", ".categories_button", function () {
			$(this).toggleClass("open");
			$(".filter_button").toggleClass("open");
			$('.filtering_form').slideToggle("slow");
		});

		$(document).on("click", ".filtering_form input", function () {
			if ($(this).data('id') == "") {
				$(".filtering_form input").prop('checked', false);
				$(".filtering_form input.all_produkts").prop('checked', true);
			} else {
				$(".filtering_form input.all_produkts").prop('checked', false);
			}
		});

		$(document).on('click', 'a[href^="#"].anchor_button', function (event) {
			event.preventDefault();

			var windowW = window.innerWidth;

			if (windowW <= 991) {
				var off = 70;
			} else {
				var off = 290;
			}

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top - off
			}, 800, 'swing');
		});

		$(document).on('click', '.goTop', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: 0
			}, 800, 'swing');
		});

		$(document).on('click', '.see_how', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $('.banner')[0].scrollHeight + 100
			}, 800, 'swing');
		});

		$(document).on('click', '.contact_details', function (event) {
			event.preventDefault();

			$(this).parent().find(".res_toggle").slideToggle("fast");
		});

		$(document).on('click', '.nav-tabs', function (event) {
			$(".res_scroll .mCSB_container").each(function () {
				$(this).css("left", "0");
			});
		});

		$(document).on('change', '.wpcf7-acceptance input', function (event) {
			$(this).parent().toggleClass("accepted");
		});

		$(".menu-icon").click(function () {
			$(this).toggleClass("active");
			$(".switchers_holder").toggleClass("active");
			$(".main-navigation").toggleClass("active");
			$(".site-header .border_bottom").toggleClass("active");
			if (!$(this).hasClass("active")) {
				$(".language_switcher").removeClass("opened");
				$(".languages").removeClass("opened");
				$(".search_button").removeClass("opened");
				$(".search-content").removeClass("opened");
				$(".search-content input").removeClass("opened");
			}
		});

		$('[data-fancybox="gallery_single"]').fancybox({
			afterLoad: function (current, previous) {
				$(".fancybox-button--arrow_right").click(function () {
					if (!$(this).hasClass("disabled")) {
						$('.slick-next').trigger("click");
					}
				});

				$(".fancybox-button--arrow_left").click(function () {
					if (!$(this).hasClass("disabled")) {
						$('.slick-prev').trigger("click");
					}
				});
			}


		});

	});

	$(window).on("load", function () {
		$(".res_scroll").mCustomScrollbar({
			theme: "dark",
			axis: "x",
			setLeft: 0
		});
	});

	$(window).on("load resize", function () {
		var winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (winWidth < 768) {
			$(".res_scroll").mCustomScrollbar({
				theme: "dark",
				axis: "x",
				setLeft: 0
			});
		} else {
			$(".res_scroll").mCustomScrollbar("destroy");
		}
	});

	if ($(".anchor_button").length) {
		var targetacc = window.location.hash,
			targetacc = targetacc.replace('#', '');

		// delete hash so the page won't scroll to it
		window.location.hash = "";

		// now whenever you are ready do whatever you want
		// (in this case I use jQuery to scroll to the tag after the page has loaded)
		$(window).on('load', function () {
			if (targetacc) {
				var windowW = window.innerWidth;

				if (windowW <= 991) {
					var off = 70;
				} else {
					var off = 290;
				}

				$('html, body').animate({
					scrollTop: $("#" + targetacc).offset().top - off
				}, 800, 'swing', function () { });
			}
		});
	}

	$(window).on("scroll", function () {
		if ($(this).scrollTop() > 41) {
			$("#masthead").addClass("stickyHeader");
		}
		else {
			$("#masthead").removeClass("stickyHeader");
		}
	})

	$('.carousel').slick({
		dots: true,
		arrows: true,
		infinite: true,
		autoplay: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
	});

	$('.produkt-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.produkt-slider-nav'
	});
	$('.produkt-slider-nav').slick({
		slidesToShow: 5,
		infinite: true,
		slidesToScroll: 1,
		asNavFor: '.produkt-slider',
		dots: false,
		dragable: true,
		swipeToSlide: true,
		arrows: true,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 423,
				settings: {
					slidesToShow: 2,
				}
			}
		]
	});

	// single produkt
	$(document).on('click', 'a[href^="#"].pdf-toggle', function (event) {
		event.preventDefault();
		$(this).fadeOut();
		$('.pdf_link.hidden').fadeIn(500).removeClass('hidden');
	});

	$(document).on('click', 'a[href^="#"].pdf-toggle', function (event) {
		event.preventDefault();
		$(this).fadeOut();
		$('.pdf_link.hidden').fadeIn(500).removeClass('hidden');
	});

	$(document).on('click', 'a[href^="#"].goto-product-layout', function (event) {
		event.preventDefault();

		var offset = $("#product-layout").offset().top - 100;
		window.scroll({ top: offset, behavior: "smooth" })
	});

})(jQuery);
